import Vue     from 'vue'
import Vuex    from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    globalSnackMessages: [],
    permissions: null,
    lang: 'en',
    stateMerchantId:'',
    stateMerchantName:'',
    userId:'',
    picture: null
  },
  mutations: {
    SET_USER (state, user) {
      state.picture = user.picture
      state.user = user.email
      state.permissions = user.role
      state.userId = user._id
    },
    SET_MERCHANTID (state, item) {
      state.stateMerchantId = item._id
      state.stateMerchantName = item.name
    },
    SET_TOKEN (state, tokens) {
      Cookies.set('token', tokens.token, { expires: 14 })
      Cookies.set('refresh_token', tokens.refresh_token, { expires: 365 })
    },
    SET_LANG (state, value) {
      state.lang = value;
    },
    GET_TOKEN () {
      return Cookies.get('token')
    },
    LOG_OUT (state) {
      state.user = null
      Cookies.remove('token')
      Cookies.remove('refresh_token')
    },
    PUSH_GLOBAL_SNACK_MESSAGES (state, message) {
      let index = state.globalSnackMessages.push(message)

      if (message.icon === 'mdi-check') {
        setTimeout(() => {
          state.globalSnackMessages.splice(index - 1, 1)
        }, 2000)
      }
    },
    REMOVE_GLOBAL_SNACK_MESSAGES (state, index) {
      state.globalSnackMessages.splice(index, 1)
    }
  }
})