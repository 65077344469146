import axios from 'axios'
import Cookies from 'js-cookie'

//const localApiRoot = 'http://localhost:3002/v1'
//const localApiRoot = 'https://api-dev-playsmart.creatives.expert/v1'
const devApiRoot = 'https://devapi.playsmarthk.com/v1';// 'https://api-dev-playsmart.creatives.expert/v1'
//const prodApiRoot = 'https://api.playsmarthk.com/v1'

//const hostname = window.location.hostname
const api = axios.create({
	baseURL: devApiRoot
	//baseURL: prodApiRoot
	// baseURL: 'https://api-dev-playsmart.creatives.expert/v1'
	/*
	baseURL : hostname.includes('localhost') || hostname.includes('192.168') ? localApiRoot
		: hostname.includes('dev') ? devApiRoot
			: prodApiRoot,
			*/
	// baseURL: 'http://localhost:3002/v1' //Local
})

api.interceptors.request.use(request => {
	request.headers.Authorization = `Bearer ${Cookies.get('token')}`;
	return request;
});

export default api
