import Vue from 'vue'
import App from './App.vue'
import router  from './router'
import store   from './store'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n'
import tc from './locales/tc'
import en from './locales/en'
import moment from 'vue-moment';
import 'moment/locale/zh-hk';

// Vue.use(require('vue-moment'));
Vue.use(moment);
Vue.use(VueI18n)

Vue.config.productionTip = false

const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

// 預設使用的語系
let locale = 'tc';
let locales = ['tc', 'en']
if (localStorage.getItem('footmark-lang') && locales.includes(localStorage.getItem('footmark-lang')) ) locale = localStorage.getItem('footmark-lang');

store.commit('SET_LANG', locale);

const i18n = new VueI18n({
  locale: locale,
  messages: {
    'tc': tc,
    'en': en
  },
  silentTranslationWarn: true
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
