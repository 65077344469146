<template>
  <v-container fluid class="ma-auto">
      <v-row align="center" justify="center" style="height:100vh" no-gutters dense>
        <v-col cols="12">
          <v-card class="ma-auto" max-width="400">
            <v-card-title>
                <h3 class="mx-auto">
                  Login
                </h3>
            </v-card-title>
            <v-card-text class="text-center">
              <v-text-field
                  v-model="email"
                  label="Email"
                  prepend-icon="mdi-face"
                  class="mt-5"
              />
              <v-text-field
                  v-model="password"
                  class="mb-3"
                  label="Password"
                  type="password"
                  prepend-icon="mdi-lock-outline"
                  @key.enter="login"
              />
              <v-btn
                  dark
                  large
                  @click="login"
              >
                  Login
              </v-btn>
            </v-card-text>
            <v-alert
              :value="alert"
              dark
              icon="mdi-alert-circle"
              border="left"
              prominent
            >
                {{ message }}
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import {mapMutations} from 'vuex'
import api from '@/api'

export default {
  components: {
  },
  methods: {
    ...mapMutations(['SET_USER', 'SET_TOKEN', 'PUSH_GLOBAL_SNACK_MESSAGES', 'SET_MERCHANTID']),
    async login () {
      if(!this.email || !this.password){
        this.message = '請輸入電郵和密碼'
        this.alert = true
      }else{
        try {
          this.loading = true
          let login = await api.post('/auth/login', { email: this.email, password: this.password })
          if (login.data) {
            if (login.data.user.role == 'admin' || login.data.user.role == 'merchant' ) {
              this.SET_TOKEN({
                token        : login.data.token.accessToken,
                refresh_token: login.data.token.refresh_token,
              })
              this.SET_USER(login.data.user)

              if(login.data.user.role == 'merchant'){
                let merchantResult = await api.get('/merchants/by-user/' + login.data.user._id)
                this.SET_MERCHANTID(merchantResult.data)
                this.router.push('/messages')
              }

            } else {
              this.message = 'you are not admin'
              this.alert = true
            }
          } else {
            this.message = '團隊正在改善/更新系統中 請稍後再登入'
            this.alert = true
          }

          this.loading = false
        } catch (error) {
          if (error.message) {
            console.log(error.message)
            var msg = error.message;
            if(error.message == "Request failed with status code 400" || error.message == "Request failed with status code 401"){
              msg = "電郵/密碼輸入錯誤，請重試，如忘記密碼，請聯絡PlaySmart職員";
            } else if(error.message == "Network Error" || error.message == "timeout of 0ms exceeded"){
              msg = "團隊正在改善/更新系統中 請稍後再登入";
            }
            this.message = msg
            this.alert = true
          } else {
            this.message = '團隊正在改善/更新系統中 請稍後再登入'
            this.alert = true
          }
          this.loading = false
        }
      }

    }
  },
  data: () => ({
    email: '',
    password: '',
    message: '',
    loading: false,
    alert: false,
  }),
}
</script>
