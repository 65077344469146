<template>
    <v-dialog v-model="profileDialog" max-width="500px">
        <v-toolbar dark>
            <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('common.profile') }}</v-toolbar-title>
            <v-spacer />
            <v-btn :disabled="profileArr.picture == '' || !profileArr.picture" color="blue darken-1"
                @click="save">{{ $t('common.save') }}</v-btn>
        </v-toolbar>
        <v-card>
            <v-card-text>
                <v-container>
                    <v-col cols="12" key="newImage">
                        <v-card>
                            <v-card-text>
                                <v-img
                                    v-if="profileArr.picture && profileArr.picture.large && profileArr.picture.large.path"
                                    class="mx-auto" :src="profileArr.picture.large.path" max-width="500" />
                                <file-pond v-else name="upload" ref="pond" :label-idle="$t('img_label')"
                                    accepted-file-types="image/jpeg, image/png" max-file-size="5MB" allow-multiple="false"
                                    v-on:removefile="profileArr.picture = ''" v-on:addfile="setOptionsOnAddFile" />
                            </v-card-text>
                            <v-card-actions
                                v-if="profileArr.picture && profileArr.picture.large && profileArr.picture.large.path">
                                <v-spacer />
                                <v-btn @click="profileArr.picture = ''" text>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-btn color="red" @click="reset" class="ml-2">{{ $t('common.reset') }}</v-btn>
                    </v-col>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import api from '@/api'
// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
    computed: {
        ...mapState(['user', 'permissions', 'menu', 'picture'])
    },
    components: {
        FilePond
    },
    mounted() {
        // let self = this
        // this.setOptions({
        // 	server: {
        // 		process: async function ( fieldName, files, metadata, load, error, progress, abort) {
        // 			try {
        //                 console.log(fieldName);
        // 				let formData = new FormData()
        // 				formData.append('file', files)

        // 				let result = await api.post('/image', formData, {
        // 					headers: {'Content-Type': 'multipart/form-data'}
        // 				})

        // 				if (result) {
        //                     self.profileArr.picture = result.data._id
        // 				}
        // 				load(true)
        // 			} catch (err) {
        // 				console.log(err.message);
        // 				abort(true)
        // 			}
        // 		}
        // 	},
        // 	imageResizeMode: 'contain',
        // })
    },
    methods: {
        ...mapMutations(
            ['SET_USER', 'SET_LANG', 'PUSH_GLOBAL_SNACK_MESSAGES']
        ),
        async open() {
            this.profileDialog = true
            let result = false

            try {
                result = await api.get('/users/profile')
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }

            if (result) this.profileArr = result.data
        },
        async save() {
            try {
                let result = await api.patch('/users/' + this.profileArr._id, this.profileArr)

                if (result) {
                    let user = await api.get('/users/profile')

                    if (user.data) {
                        this.SET_USER(user.data)
                    }
                    this.close()
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }
        },
        setOptionsOnAddFile() {
            let self = this
            this.setOptions(
                {
                    server: {
                        process: async function (fieldName, files, metadata, load, error, progress, abort) {
                            try {
                                let formData = new FormData()
                                formData.append('file', files)

                                let result = await api.post('/image', formData, {
                                    headers: { 'Content-Type': 'multipart/form-data' }
                                })

                                if (result) {
                                    self.profileArr.picture = result.data._id
                                }
                                load(true)
                            } catch (err) {
                                console.log(err.message);
                                abort(true)
                            }
                        }
                    },
                })
        },
        async reset() {
            try {
                let result = await api.post('/auth/send-password-reset', { 'email': this.user })

                if (result) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t("global_alert.success_reset"),
                        icon: 'mdi-check',
                    })
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }
        },
        close() {
            this.profileDialog = false
            this.uploadImageFile = []
            this.profileArr = []
        }
    },
    data() {
        return {
            setOptions,
            profileDialog: false,
            uploadImageFile: [],
            profileArr: []
        };
    }
}
</script>