import Vue from 'vue'
import Router from 'vue-router'
import ParentsWrapper from "@/components/ContentWrapper.vue"

const includPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return includPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "page_name.dashboard",
			materialIcon: "mdi-view-dashboard",
			component: () => import('@/views/Dashboard'),
			meta: {
				permissions: ["admin", "merchant"]
			}
		},
		{
			path: "/statistic",
			name: "page_name.statistic",
			materialIcon: "mdi-trending-up",
			component: () => import('@/views/Statistic'),
			meta: {
				permissions: ["admin"]
			}
		},
		{
			path: "/coming_works",
			name: "page_name.coming_works",
			materialIcon: "mdi-chart-tree",
			component: () => import('@/views/Work/CommingWorks'),
			meta: {
				permissions: ["merchant"]
			}
		},
		{
			path: "/timetable",
			name: "page_name.timetable",
			materialIcon: "mdi-calendar-month",
			component: () => import('@/views/Work/MerchantTimeTable'),
			meta: {
				permissions: ["merchant"]
			}
		},
		{
			path: "/user",
			name: "page_name.user_management",
			materialIcon: "mdi-account",
			component: () => import('@/views/Users/UserList'),
			meta: {
				permissions: ["admin"]
			}
		},
		{
			path: "/merchant",
			name: "common.merchant",
			component: ParentsWrapper,
			materialIcon: "mdi-account-tie",
			meta: {
				permissions: ["admin", "merchant"]
			},
			children: [
				{
					path: "management",
					name: "page_name.merchant_management",
					merchantName: "page_name.merchant_management_mer",
					component: () => import('@/views/Merchants/MerchantList'),
					meta: {
						permissions: ["admin", "merchant"],
						merPageName: "page_name.merchant_management_mer"
					}
				},
				{
					path: "categories",
					name: "page_name.categories",
					component: () => import('@/views/Categories/CategoriesList'),
					meta: {
						permissions: ["admin"]
					}
				},
				{
					path: "merchant_subscription_request",
					name: "page_name.merchant_subscription_request",
					component: () => import('@/views/MerchantSubscriptionRequest/MerchantSubscriptionRequestList'),
					meta: {
						permissions: ["admin"]
					}
				},
				{
					path: "plan",
					name: "page_name.merchant_plan",
					component: () => import('@/views/MerchantPlan/MerchantPlanList'),
					meta: {
						permissions: ["admin"]
					}
				},
				{
					path: "merchant_chat_room",
					name: "page_name.merchant_chat_room",
					component: () => import('@/views/Merchants/MerchantChatRoom'),
					meta: {
						permissions: ["admin"]
					}
				},
			]
		},
		{
			path: "/teacher",
			name: "page_name.teacher",
			materialIcon: "mdi-school",
			component: () => import('@/views/Teacher/TeacherList'),
			meta: {
				permissions: ["admin", "merchant"]
			},
			props: route => ({ query: route.query.id }),
		},
		{
			path: "/service",
			name: "page_name.merchant_activity",
			merchantName: "page_name.merchant_activity_mer",
			component: () => import('@/views/Service/ServiceList'),
			materialIcon: "mdi-order-bool-ascending-variant",
			meta: {
				permissions: ["admin", "merchant"],
				merPageName: "page_name.merchant_activity_mer"
			},
			props: route => ({ query: route.query.id }),
		},
		{
			path: "/serviceranking",
			name: "page_name.service_ranking",
			materialIcon: "mdi-sort",
			component: () => import('@/views/Service/ServiceRanking'),
			meta: {
				permissions: ["admin"]
			}
		},
		{
			path: "/package",
			name: "page_name.service_package",
			materialIcon: "mdi-package-variant-closed",
			component: () => import('@/views/PackageService/ServiceList'),
			meta: {
				permissions: ["admin", "merchant"]
			},
			props: route => ({ query: route.query.id }),
		},
		{
			path: "/order",
			name: "page_name.order",
			component: ParentsWrapper,
			materialIcon: "mdi-bookmark-outline",
			meta: {
				permissions: ["admin", "merchant"]
			},
			children: [
				{
					path: "service",
					name: "page_name.service_order",
					component: () => import('@/views/Order/OrderServiceList'),
					meta: {
						permissions: ["admin", "merchant"]
					},
					props: route => ({ query: route.query.id }),
				},
				{
					path: "package",
					name: "page_name.package_order",
					component: () => import('@/views/Order/OrderPackageList'),
					meta: {
						permissions: ["admin", "merchant"]
					},
					props: route => ({ query: route.query.id }),
				},
			]
		},
		{
			path: "/payment_method",
			name: "page_name.payment_method",
			materialIcon: "mdi-credit-card",
			component: () => import('@/views/PaymentMethod/PaymentMethodList'),
			meta: {
				permissions: ["admin"]
			}
		},
		{
			path: "/transactions",
			name: "page_name.transactions",
			materialIcon: "mdi-currency-usd",
			component: () => import('@/views/Transactions/TransactionsList'),
			meta: {
				permissions: ["admin"]
			}
		},
		/* {
		 path: "/image_list",
		 name: "page_name.image_list",
		 materialIcon: "mdi-file-image-outline",
		 component: () => import('@/views/Image/ImageList'),
		 meta: {
		 permissions: ["admin"]
		 }
		 }, */
		{
			path: "/banner_list",
			name: "page_name.banner_list",
			component: ParentsWrapper,
			materialIcon: "mdi-image-area",
			meta: {
				permissions: ["admin"]
			},
			children: [
				{
					path: "home",
					name: "page_name.home_banner_list",
					component: () => import('@/views/Banner/HomeBannerList'),
					meta: {
						permissions: ["admin"]
					}
				},
				{
					path: "member",
					name: "page_name.member_banner_list",
					component: () => import('@/views/Banner/MemberBannerList'),
					meta: {
						permissions: ["admin"]
					}
				},
			]
		},
		{
			path: "/highlight",
			name: "page_name.highlight",
			component: ParentsWrapper,
			materialIcon: "mdi-star-circle-outline",
			meta: {
				permissions: ["admin"]
			},
			children: [
				{
					path: "lastest",
					name: "page_name.lastest_highlight",
					component: () => import('@/views/Highlight/LatestHighlightList'),
					meta: {
						permissions: ["admin"]
					}
				},
				{
					path: "hot",
					name: "page_name.hot_highlight",
					component: () => import('@/views/Highlight/HotHighlightList'),
					meta: {
						permissions: ["admin"]
					}
				},
				{
					path: "package",
					name: "page_name.package_highlight",
					component: () => import('@/views/Highlight/PackageHighlightList'),
					meta: {
						permissions: ["admin"]
					}
				},
				{
					path: "discount",
					name: "page_name.discount_highlight",
					component: () => import('@/views/Highlight/DiscountHighlightList'),
					meta: {
						permissions: ["admin"]
					}
				},
				{
					path: "category",
					name: "page_name.category_highlight",
					component: () => import('@/views/Highlight/CategoryHighlightList'),
					meta: {
						permissions: ["admin"]
					}
				},
			]
		},
		{
			path: "/setting",
			name: "page_name.setting",
			materialIcon: "mdi-cog",
			component: () => import('@/views/Setting'),
			meta: {
				permissions: ["admin"]
			}
		},
		{
			path: "/messages",
			name: "page_name.message",
			materialIcon: "mdi-forum",
			component: () => import('@/views/Message/MessageList'),
			meta: {
				permissions: ["merchant"]
			}
		}
	],
})

export default router;
