<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed app :width="(this.$i18n.locale == 'tc')?  250 : 350">
			<v-list dense nav expand>
				<v-list-item>
					<v-select
						v-model="$i18n.locale"
						:items="langOptions"
						item-text="name"
						item-value="value"
						@change="changeLang"
						dense
					/>
				</v-list-item>
				<template v-for="item in $router.options.routes">
					<v-list-item
						v-if="item.meta.permissions.includes(permissions) && !item.children && item.name"
						:key="item.name"
						:to="item.path"
					>
						<v-list-item-action>
							<v-icon>{{ item.materialIcon }}</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{ (typeof item.merchantName !== 'undefined' && permissions != 'admin')? $t(item.merchantName) : $t(item.name)  }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-group
						v-if="item.meta.permissions.includes(permissions) && item.children && item.name"
						:group="item.path"
						:key="item.name"
						:prepend-icon="item.materialIcon"
					>
						<template v-slot:activator>
							<v-list-item-title>{{ (typeof item.merchantName !== 'undefined' && permissions != 'admin')? $t(item.merchantName) : $t(item.name) }}</v-list-item-title>
						</template>
						<template v-for="subItem in item.children">
							<v-list-item
								v-if="subItem.meta.permissions.includes(permissions)"
								:key="subItem.name"
								:to="item.path + '/' + subItem.path"
								:value="true"
								link
								dense
								class="pl-5"
							>
								<v-list-item-title>
									{{ (typeof subItem.merchantName !== 'undefined' && permissions != 'admin')? $t(subItem.merchantName) : $t(subItem.name)}}
								</v-list-item-title>
							</v-list-item>
						</template>
					</v-list-group>
				</template>
			</v-list>
		</v-navigation-drawer>
		<v-app-bar app color="info_new" dark>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer" />
			<v-toolbar-title> Playsmart CMS - {{ (permissions != 'admin' && $route.meta.merPageName)? $t($route.meta.merPageName) : $t($route.name)  }}</v-toolbar-title>
			<v-spacer/>
			<v-avatar class="px-4" @click="chgImg">
				<img
					v-if="picture && picture.large && picture.large.path"
					:src="picture.large.path"
				>
				<v-icon dark v-else>
					mdi-account-circle
				</v-icon>
			</v-avatar>
			{{ (user)? user : '' }}
			<v-icon class="pa-2" @click="logout()">mdi-exit-to-app</v-icon>
		</v-app-bar>
		<ProfileDialog ref="dialog" />
	</div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Cookies from 'js-cookie';
import ProfileDialog from './ProfileImg'

export default {
	computed: {
		...mapState(['user', 'permissions', 'menu','picture'])
	},
	components:{
		ProfileDialog
	},
	methods: {
		...mapMutations(
			['SET_USER', 'SET_LANG', 'PUSH_GLOBAL_SNACK_MESSAGES']
		),
		logout(){
            this.$router.push({ path: '/' })
			this.SET_USER('')
			Cookies.remove('token')
			Cookies.remove('refresh_token')
		},
		changeLang(newLang) {
			this.SET_LANG(newLang)
			this.$i18n.locale = newLang;
			localStorage.setItem('footmark-lang', newLang);
		},
		chgImg(){
			this.$refs.dialog.open()
		}
	},
	data() {
		return {
			drawer: null,
			langOptions:[
				{name: '中文', value: 'tc'},
				{name: 'Eng', value: 'en'}
			]
		};
	}
};
</script>
