<template >
  <v-app>
    <div v-if="!user" >
      <v-main>
        <Login />
      </v-main>
    </div>
    <div v-else>
      <Navigation />
      <v-main>
        <router-view />
        <template v-for="(snack, index) in globalSnackMessages">
          <v-snackbar
            :timeout="('mdi-close')? -1 : 5000"
            :key="index"
            :value="globalSnackMessages[index]"
            absolute
            top
            right
            :style="'margin-top: ' + (index) * 50 + 'px; z-index: 99999'"
          >
            <v-icon @click="REMOVE_GLOBAL_SNACK_MESSAGES(index)">{{ snack.icon }}</v-icon>
            <span v-html="snack.message.split('\n').join('<br>')"/>
          </v-snackbar>
        </template>
      </v-main>
    </div>
  </v-app>
</template>

<script>
  import Login from "@/views/Users/Login"
  import Navigation from "@/components/Navigation"
  import {mapState, mapMutations} from 'vuex'
  import api from '@/api'
  import Cookies from 'js-cookie'

  export default {
    name: 'App',
    computed  : {
      ...mapState(['user', 'globalSnackMessages'])
    },
    components : {
      Login,
      Navigation
    },
    async created () {
      try {
        let token = Cookies.get('token')

        if (token) {
          let user = await api.get('/users/profile')

          if (user.data) {
            if(user.data.role == 'merchant'){
              let merchantResult = await api.get('/merchants/by-user/' + user.data._id)
              this.SET_MERCHANTID(merchantResult.data)
            }
            this.SET_USER(user.data)
          } else {
            this.LOG_OUT()
          }
        } else {
          this.LOG_OUT()
        }
      } catch (error) {
        console.log(error);
        this.LOG_OUT()
      }
    },
    mounted(){
    },
    methods: {
      ...mapMutations(['SET_USER', 'SET_TOKEN','LOG_OUT', 'REMOVE_GLOBAL_SNACK_MESSAGES', 'SET_MERCHANTID']),
    },
    data: () => ({
    }),
  };
</script>
